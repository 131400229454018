import React from "react"
import { Box, Grid } from "@mui/material"
import { MarketPlaceFilterMenu, MarketPlaceSortMenu, MarketPlaceTable } from "../../../component"
import { MusicAPI } from "../../../api"
import { FilterState } from "../../../types/marketPlace"
import { useUserInfo } from "../../../context/UserInfoProvider"

/**
 * All tab in market place page
 */
export default function All() {
  const [marketPlaceData, setMarketPlaceData] = React.useState([])
  const [, setError] = React.useState("")
  const { userToken } = useUserInfo()

  // Filtering and sorting state for getMarketPlaceData
  const [filter, setFilter] = React.useState<FilterState>({
    style: [],
    sortBy: "uploadTimestamp",
    sortOrder: "DESC",
  })

  React.useEffect(() => {
    const getMarketPlaceData = async () => {
      const response = userToken
        ? await MusicAPI.retrieveWithPurchased({
            pageIndex: 0,
            pageSize: 0,
            styles: filter?.style,
            sortBy: filter?.sortBy,
            sortOrder: filter?.sortOrder,
            musicType: "ALL",
          })
        : await MusicAPI.retrieve({
            pageIndex: 0,
            pageSize: 0,
            styles: filter?.style,
            sortBy: filter?.sortBy,
            sortOrder: filter?.sortOrder,
            musicType: "ALL",
          })
      if (response?.err) {
        setError(response?.err)
        return
      }
      setMarketPlaceData(response?.res)
    }
    getMarketPlaceData()
  }, [filter, userToken])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container columnSpacing={2}>
        <Grid item xs={2}>
          <MarketPlaceFilterMenu filter={filter} setFilter={setFilter} />
        </Grid>

        <Grid item xs={10}>
          <MarketPlaceSortMenu filter={filter} setFilter={setFilter} />
          <MarketPlaceTable data={marketPlaceData} />
        </Grid>
      </Grid>
    </Box>
  )
}
