import { Box, Button } from "@mui/material"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import WMC_LOGO from "../../assets/brands/logo-sq.png"
import { colors } from "../../theme/colors"
import { createDownloadUrl } from "../../util"

import FavoriteIcon from "@mui/icons-material/Favorite"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import ShortcutIcon from "@mui/icons-material/Shortcut"
import TurnedInIcon from "@mui/icons-material/TurnedIn"
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot"
import VisibilityIcon from "@mui/icons-material/Visibility"

import PauseIcon from "@mui/icons-material/Pause"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CartAPI, SubscriptionAPI } from "../../api"
import { Buttons } from "../../component/MarketPlace/Table/Buttons"
import WaveMusicPlayer from "../../component/WaveMusicPlayer/WaveMusicPlayer"
import useMUIMediaQuery from "../../hooks/useMUIMedia"

type MusicDetailMusicProps = {
  _id: string
  name: string
  description: string
  imageUrl?: string
  image?: {
    downloadUrl: string
  }
  like: number
  view: number
  saved: boolean
  purchaseNumber: number
  ownedUser: string
  price: number
  downloadUrl: string
  watermarkUrl?: string
  purchased: boolean
}
/**
 * Music Detail Music Section
 */
export default function MusicDetailMusic(props: MusicDetailMusicProps) {
  const {
    _id,
    name,
    description,
    imageUrl,
    image,
    like,
    view,
    saved,
    purchaseNumber,
    ownedUser,
    price,
    downloadUrl,
    watermarkUrl,
    purchased,
  } = props
  const navigate = useNavigate()
  const projectImageUrl = createDownloadUrl(image?.downloadUrl)
  const musicUrl = createDownloadUrl(purchased ? downloadUrl : watermarkUrl)
  const [isPlaying, setIsPlaying] = useState(false)
  const { isMobile } = useMUIMediaQuery()
  const [subscribed, setSubscribed] = useState(false)
  const [, setError] = useState("")

  const handleAddToCart = async (musicId: string) => {
    try {
      const response = await CartAPI.addToCart(musicId)
      if (response?.err) {
        console.error("Failed to add item to cart")
        return
      }
      console.log("Item added to cart:", response)
      // Handle success (e.g., show a notification or update the UI)
    } catch (err) {
      console.error("Failed to add item to cart:", err)
    }
  }
  useEffect(() => {
    const checkSubscription = async () => {
      const response = await SubscriptionAPI.checkSubscription()
      if (response?.err) {
        setError(response?.err)
        return
      }
      setSubscribed(response?.res.success)
    }
    checkSubscription()
  }, [])
  return (
    <Grid container marginTop={2} textAlign="left">
      <Grid item container>
        <Grid item xs={12} sm={3} paddingRight={1}>
          <img src={projectImageUrl || imageUrl || WMC_LOGO} alt="project img" width="100%" />
        </Grid>
        <Grid item container xs={12} sm={9}>
          <Grid item xs={9}>
            <Box display={"flex"} alignItems="center" mb={isMobile ? 1 : 0}>
              <Typography variant="h5">{name}</Typography>
              {isPlaying ? (
                <IconButton aria-label="pause-button" onClick={() => setIsPlaying(false)}>
                  <PauseIcon />
                </IconButton>
              ) : (
                <IconButton aria-label="player-button" onClick={() => setIsPlaying(true)}>
                  <PlayArrowIcon />
                </IconButton>
              )}
            </Box>
            <Typography variant="body2">{description}</Typography>
            <Box display={"flex"} gap={1} mb={isMobile ? 1 : 0}>
              <Typography variant="body2" color={colors.grey}>
                {`Owned by `}
              </Typography>
              <Typography variant="body2" color={colors.orange}>
                {ownedUser ?? ""}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={isMobile ? 1 : 0}>
              <Box alignItems="center" flex={1} display={"flex"} sx={{ color: colors.grey }}>
                <VisibilityIcon />
                <Typography sx={{ ml: "2px" }} variant="body2">
                  {view}
                </Typography>
              </Box>
              <Box
                alignItems="center"
                flex={1}
                sx={{ ml: "15px", display: "flex", color: colors.grey }}
              >
                <FavoriteIcon />
                <Typography sx={{ ml: "2px" }} variant="body2">
                  {like}
                </Typography>
              </Box>
              <Box
                alignItems="center"
                flex={1}
                sx={{ ml: "15px", display: "flex", color: colors.grey }}
              >
                <ShoppingCartIcon />

                <Typography sx={{ ml: "2px" }} variant="body2">
                  {purchaseNumber ?? 0}
                </Typography>
              </Box>
              <Box flex={1} sx={{ ml: "15px" }}>
                <IconButton
                  edge="start"
                  sx={{
                    padding: "4px, 10px, 4px, 10px",
                    color: colors.orange,
                  }}
                >
                  <ShortcutIcon />
                  {!isMobile && (
                    <Typography sx={{ ml: "2px", color: colors.orange }}>Share</Typography>
                  )}
                </IconButton>
              </Box>
              <Box flex={1} sx={{ ml: "15px" }}>
                <IconButton
                  edge="start"
                  sx={{
                    padding: "4px, 10px, 4px, 10px",
                    color: colors.orange,
                  }}
                >
                  {saved ? <TurnedInIcon color="primary" /> : <TurnedInNotIcon />}
                  {!isMobile && (
                    <Typography sx={{ ml: "2px", color: saved ? colors.orange : undefined }}>
                      {saved ? "Saved" : "Save"}
                    </Typography>
                  )}
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} display={"flex"} alignItems={"baseline"} mb={isMobile ? 2 : 0}>
            <Typography variant="body2" bgcolor={colors.grey3} mr={1} padding={1}>
              {purchased ? "FREE" : `HKD ${price}`}
            </Typography>
            {purchased ? (
              <Buttons.Download onClick={() => console.log("Download button clicked")} />
            ) : (
              <Buttons.BuyNowOrAddToCart
                onBuyNowClick={() => console.log("Buy now button clicked")}
                onAddToCartClick={() => handleAddToCart(_id)}
              />
            )}
          </Grid>
          <Grid item xs={12} mb={isMobile ? 2 : 0}>
            {musicUrl && (
              <WaveMusicPlayer
                playingAudio={isPlaying}
                waveformHeight={isMobile ? 30 : 60}
                volume={0.5}
                audioUrl={musicUrl}
              />
            )}
          </Grid>
          <Grid container justifyContent={"center"}>
            {!subscribed && (
              <Grid>
                <Button variant="outlined" onClick={() => navigate("/shoppingCart/yearlyStandard")}>
                  SUBSCRIBE FOR UNLIMITED DOWNLOAD TRACKS
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
