import { Box, Button } from "@mui/material"
import { IResolveParams, LoginSocialFacebook } from "reactjs-social-login"
import { Facebook, Google } from "@mui/icons-material"
import { config } from "../config/client-config"
import React from "react"
import { GoogleLogin } from "@react-oauth/google"
import { loadScript } from "../util"

declare global {
  interface Window {
    google: any
  }
}

interface SocialMediaLoginProps {
  handleLogin: (_provider: string, _data: any) => void
  handleReject: (_err: any) => void
}

/**
 *
 * Social Media Login Component
 */
export default function SocialMediaLogin(props: SocialMediaLoginProps) {
  const { handleLogin, handleReject } = props
  const [isLogin, setIsLogin] = React.useState(false)

  React.useEffect(() => {
    const trigger = () => {
      loadScript("https://accounts.google.com/gsi/client")
    }

    trigger()
  })

  const handleGoogleLogin = (res: any) => {
    setIsLogin(true)
    handleLogin("google", res)
  }

  const handleFacebookLogin = ({ data }: IResolveParams) => {
    setIsLogin(true)
    handleLogin("facebook", data)
  }

  return (
    <Box letterSpacing={2} sx={{ textAlign: "center" }}>
      <div style={{ width: "350px", margin: "0 auto", position: "relative" }}>
        <Button
          variant="outlined"
          startIcon={<Google />}
          size="large"
          sx={{ width: "350px" }}
          disabled={isLogin}
        >
          Login/Signup with Google
        </Button>
        <div
          style={{
            width: "350px",
            margin: "0 auto",
            opacity: "0%",
            position: "absolute",
            top: 0,
          }}
        >
          <GoogleLogin
            theme="outline"
            width="350px"
            onSuccess={(res) => handleGoogleLogin(res)}
            onError={() => {
              handleReject(new Error("Google Login Failed"))
            }}
          />
        </div>
      </div>
      <br />
      <div style={{ width: "350px", margin: "0 auto" }}>
        <LoginSocialFacebook
          response_type="code"
          appId={config.facebookAppId}
          onResolve={handleFacebookLogin}
          onReject={handleReject}
        >
          <Button
            variant="outlined"
            startIcon={<Facebook />}
            size="large"
            sx={{ width: "350px" }}
            disabled={isLogin}
          >
            Login/Signup with Facebook
          </Button>
        </LoginSocialFacebook>
      </div>
    </Box>
  )
}
