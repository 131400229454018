import React from "react"
import { Box, Grid, Typography, IconButton, Button } from "@mui/material"
import CancelIcon from "@mui/icons-material/Cancel"
import { colors } from "../../theme/colors"

type ShoppingCartMusicItemProps = {
  title: string
  artist: string
  price: number
  format: string
  imageUrl?: string
  onRemove: () => void
}

/**
 ShopingCartMusicItem component
 */
export default function ShoppingCartMusicItem({
  title,
  artist,
  price,
  format,
  imageUrl,
  onRemove,
}: ShoppingCartMusicItemProps) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: 2,
        marginBottom: 2,
        border: `1px solid ${colors.grey}`,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
        <img
          src={imageUrl}
          alt={`${title} cover`}
          style={{ width: 50, height: 50, marginRight: 16 }}
        />
        <Box>
          <Typography variant="subtitle1" sx={{ color: "#fff" }}>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: colors.grey }}>
            {artist}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2" sx={{ color: colors.grey, marginRight: 16 }}>
          Format: {format}
        </Typography>
        {price !== 0 ? (
          <Typography
            variant="body1"
            sx={{
              color: "#fff",
              marginRight: 16,
              p: "8px",
              bgcolor: colors.darkGrey2,
              borderRadius: "4px",
            }}
          >
            HKD {price}
          </Typography>
        ) : (
          <Typography
            variant="body1"
            sx={{
              color: "#fff",
              marginRight: 16,
              p: "8px",
              bgcolor: colors.darkGrey2,
              borderRadius: "4px",
            }}
          >
            Standard
          </Typography>
        )}
        <Button
          variant="outlined"
          sx={{
            borderColor: colors.grey,
            color: colors.grey,
            marginRight: 16,
            textTransform: "none",
          }}
          startIcon={<CancelIcon />}
          onClick={onRemove}
        >
          Remove
        </Button>
      </Box>
    </Box>
  )
}
